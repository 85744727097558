<template>
  <div v-if="form.productId" class="container">
    <a-form-model class="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-row :gutter="[24, 16]" type="flex" align="bottom">
        <a-col class="w-1/2">
          <a-form-model-item label="产品ID" prop="productId">
            <a-input disabled v-model="form.productId" /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="产品名称" prop="name">
            <a-input :disabled="permissionDisable" v-model="form.name" /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2"
          ><a-form-model-item label="产品类型" prop="type">
            <a-select :disabled="permissionDisable" v-model="form.type" @change="handleProductTypeChange">
              <a-select-option
                v-for="productType in metaData.productTypes"
                :key="productType.value"
                :value="productType.value"
                >{{ productType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2"
          ><a-form-model-item label="产品设备类型" :prop="'metadata.productDeviceType'">
            <a-select v-model="form.metadata.productDeviceType" :disabled="permissionDisable">
              <a-select-option
                v-for="deviceType in metaData.productDeviceTypes"
                :key="deviceType.value"
                :value="deviceType.value"
                >{{ deviceType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2" v-show="form.type === $constRepository.product.PRODUCT_TYPE_OPTIONS.root">
          <a-form-model-item label="产品地址类型" prop="addressType">
            <a-select v-model="form.addressType" :disabled="permissionDisable">
              <a-select-option
                v-for="addressType in metaData.productAddressTypes"
                :key="addressType.value"
                :value="addressType.value"
                >{{ addressType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2" v-show="form.type === $constRepository.product.PRODUCT_TYPE_OPTIONS.root">
          <a-form-model-item label="子设备产品地址类型" prop="subAddressType">
            <a-select v-model="form.subAddressType" :disabled="permissionDisable">
              <a-select-option
                v-for="addressType in metaData.subProductAddressTypes"
                :key="addressType.value"
                :value="addressType.value"
                :title="addressType.name"
                >{{ addressType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2">
          <a-form-model-item label="产品控制协议类型" prop="protocol">
            <a-select :disabled="permissionDisable" v-model="form.protocol">
              <a-select-option
                v-for="productProtocol in metaData.productProtocols"
                :key="productProtocol.value"
                :value="productProtocol.value"
              >
                {{ productProtocol.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2">
          <a-form-model-item label="产品使用频率" prop="useFrequency">
            <a-select v-model="form.useFrequency" :disabled="permissionDisable">
              <a-select-option
                v-for="frequency in $constRepository.product.USE_FREQUENCY_OPTIONS"
                :key="frequency.value"
                :value="frequency.value"
              >
                {{ frequency.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item></a-col
        >
        <a-col class="w-1/2">
          <a-form-model-item label="中控内部驱动标识符" prop="driver">
            <a-input v-model="form.driver" :disabled="permissionDisable" /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2" v-show="form.type == 1">
          <a-form-model-item label="可连接子设备数量" prop="childrenCount" >
            <a-input-number :disabled="permissionDisable" class="w-full" v-model="form.childrenCount" ref="childrenCount" /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2" v-show="form.childrenCount != 0 && form.type == 1">
          <a-form-model-item label="子节点产品ID" prop="children">
            <a-input
            :disabled="permissionDisable"
              ref="children"
              @change="handleChildrenChange"
              v-model="childrenString"
              placeholder="多个id请使用英文逗号“,”分割"
            /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2" v-if="form.childrenCount == -1">
          <a-form-model-item label="子设备数量限制" prop="childrenCountLimit">
            <a-input-number :disabled="permissionDisable" class="w-full" v-model="form.childrenCountLimit"  /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="用户是否可见" prop="userVisible">
            <a-switch
              :disabled="permissionDisable"
              v-model="form.userVisible"
              checked-children="是"
              un-checked-children="否"
              default-checked
            /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="强电设备" :prop="'metadata.strongCurrent'">
            <a-switch
            :disabled="permissionDisable"
              v-model="form.metadata.strongCurrent"
              checked-children="是"
              un-checked-children="否"
              default-checked
            /> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-item label="备注" prop="remark">
            <a-textarea v-model="form.remark" :disabled="permissionDisable" placeholder="请输入备注" :rows="4" />
          </a-form-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="属性" prop="propertyIds">
            <a-input
              :disabled="permissionDisable"
              :value="`已选择${form.propertyIds.length}个(${form.properties.map((property) => property.identifier)})`"
              @click="handlePropertySelect"
            >
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="发布区域" prop="regionIdentifiers">
            <a-input
              :disabled="permissionDisable"
              :value="`已选择${form.regionIdentifiers.length}个(${form.regionIdentifiers.map((region) => region)})`"
              @click="handleRegionSelect"
            >
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="负载类型" prop="loadedDeviceTypeIds">
            <a-input
              :value="`已选择${form.loadedDeviceTypeIds.length}个(${form.loadedDeviceTypes.map((loadedDevice) => {
                return `${loadedDevice.name}-(id:${loadedDevice.loadedDeviceTypeId})`;
              })})`"
              @click="handleLoadedDeviceSelect"
            >
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="服务" prop="serviceIds">
            <a-input
              :disabled="permissionDisable"
              :value="`已选择${form.serviceIds ? form.serviceIds.length : 0}个(${form.services.map(
                (service) => service.identifier,
              )})`"
              @click="handleServiceSelect"
            >
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="配置" prop="serviceIds">
            <a-input
              :disabled="permissionDisable"
              :value="`已选择${
                form.configurationItemIds ? form.configurationItemIds.length : 0
              }个(${form.configurationItems.map((configuration) => configuration.identifier)})`"
              @click="handleConfigurationSelect"
            >
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="产品图标" prop="iconImage">
            <div class="clearfix">
              <a-upload
                :disabled="permissionDisable"
                list-type="picture-card"
                accept="image/*"
                :customRequest="iconCustomRequest"
                :file-list="iconImage"
                @preview="handlePreview"
                :remove="removeiconImages"
              >
                <div v-if="iconImage && iconImage.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="关联产品型号">
            <a-button @click="handleAddBtn" :disabled="permissionDisable">添加</a-button>
            <a-table :data-source="this.form.internalModels" rowKey="identifier" size="middle">
              <a-table-column align="center" title="产品型号" data-index="identifier" key="identifier"></a-table-column>
              <a-table-column align="center" title="产品名称" data-index="name" key="name"></a-table-column>
              <a-table-column align="center" title="操作" data-index="operation" key="operation">
                <template slot-scope="text, record">
                  <a-button @click="jumpToDetail(record)" type="link" style="color: #70b603">详情</a-button>
                  <a-button @click="handleDeleteProduct(record)" type="link" style="color: #d9001b">删除</a-button>
                </template>
              </a-table-column>
            </a-table>
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="产品编辑器" v-if="!permissionDisable">
            <vue-json-editor
              :disabled="true"
              v-model="form"
              :showBtns="false"
              :mode="'code'"
              lang="zh"
              style="height: 800px"
            /> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-item class="group-btn">
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button class="create-btn" style="margin-left: 10px" @click="goBack">返回</a-button>
          </a-form-item></a-col
        >
      </a-row>
    </a-form-model>
    <product-properties-selector ref="productPropertiesSelector" />
    <region-select-modal ref="regionSelectModal" />
    <loaded-device-select-modal ref="loadedDeviceSelectModal" />
    <product-service-selector ref="productServiceSelector" />
    <product-configuration-selector ref="productConfigurationSelector" />
    <upload-progress ref="uploadProgress" />
    <ProductModelTransferModal ref="productModelTransferModal" @selectedModels="handleSelectedModel" />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import LoadedDeviceSelectModal from '../loaded-device/components/LoadedDeviceSelectModal';
import ProductPropertiesSelector from './components/ProductPropertiesSelector';
import RegionSelectModal from '../region/components/RegionSelectModal';
import ProductConfigurationSelector from './components/ProductConfigurationSelector';
import ProductServiceSelector from './components/ProductServiceSelector';
import UploadProgress from '../../../components/upload-progress/UploadProgress';
import ProductModelTransferModal from './components/ProductModelTransfer';

export default {
  name: 'ProductEditor',
  components: {
    ProductPropertiesSelector,
    RegionSelectModal,
    vueJsonEditor,
    LoadedDeviceSelectModal,
    ProductConfigurationSelector,
    ProductServiceSelector,
    UploadProgress,
    ProductModelTransferModal,
  },
  async activated() {
    if (this.$route.params.id) {
      this.listProductById(this.$route.params.id);
    }
    await this.getMetaData();
  },
  computed: {
    rules() {
      return this.form.type === this.$constRepository.product.PRODUCT_TYPE_OPTIONS.root
        ? {
            name: [{ required: true, message: '产品名称 不能为空', trigger: 'blur' }],
            productId: [{ required: true, message: '产品ID 不能为空', trigger: 'blur' }],
            useFrequency: [{ required: true, message: '产品使用频率 不能为空', trigger: 'blur' }],
            protocol: [{ required: true, message: '产品控制协议类型 不能为空', trigger: 'blur' }],
            type: [{ required: true, message: '产品类型 不能为空', trigger: 'blur' }],
            addressType: [{ required: true, message: '产品地址类型 不能为空', trigger: 'blur' }],
            subAddressType: [{ required: true, message: '子设备产品地址类型 不能为空', trigger: 'blur' }],
            'metadata.productDeviceType': [{ required: true, message: '产品设备类型 不能为空', trigger: 'blur' }],
            userVisible: [{ required: true, message: '用户是否可见 不能为空', trigger: 'blur' }],
            'metadata.strongCurrent': [{ required: true, message: '强电设备 不能为空', trigger: 'blur' }],
          }
        : {
            name: [{ required: true, message: '产品名称 不能为空', trigger: 'blur' }],
            productId: [{ required: true, message: '产品ID 不能为空', trigger: 'blur' }],
            useFrequency: [{ required: true, message: '产品使用频率 不能为空', trigger: 'blur' }],
            protocol: [{ required: true, message: '产品控制协议类型 不能为空', trigger: 'blur' }],
            type: [{ required: true, message: '产品类型 不能为空', trigger: 'blur' }],
            'metadata.productDeviceType': [{ required: true, message: '产品设备类型 不能为空', trigger: 'blur' }],
            userVisible: [{ required: true, message: '用户是否可见 不能为空', trigger: 'blur' }],
            'metadata.strongCurrent': [{ required: true, message: '强电设备 不能为空', trigger: 'blur' }],
          };
    },
  },
  data() {
    return {
      permissionDisable: false,
      filterOptions: this.$constRepository.product.PRODUCT_PROTOCOL_OPTIONIS,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      childrenString: '',
      form: {
        name: undefined,
        productId: undefined,
        driver: undefined,
        protocol: undefined,
        type: undefined,
        useFrequency: null,
        remark: '',
        userVisible: false,
        propertyIds: [],
        regionIdentifiers: [],
        loadedDeviceTypeIds: [],
        addressType: null,
        subAddressType: null,
        model: [],
        children: [],
        childrenCount: 0,
        childrenCountLimit: null,
        imageTypeIds: [],
        deviceIds: [],
        metadata: {
          sensorZoneIds: [],
          strongCurrent: false,
          productDeviceType: null,
        },
        configurationItems: [],
        services: [],
        serviceIds: [],
        configurationItemIds: [],
        internalModels: [],
      },
      iconImage: [],
      previewVisible: false,
      previewImage: '',
      metaData: {},
    };
  },
  methods: {
    dataInit() {
      this.form = {
        name: undefined,
        productId: undefined,
        driver: undefined,
        protocol: undefined,
        type: undefined,
        useFrequency: null,
        remark: '',
        userVisible: false,
        propertyIds: [],
        regionIdentifiers: [],
        loadedDeviceTypeIds: [],
        model: [],
        children: [],
        addressType: null,
        subAddressType: null,
        childrenCount: 0,
        childrenCountLimit: null,
        imageTypeIds: [],
        deviceIds: [],
        metadata: {
          sensorZoneIds: [],
          strongCurrent: false,
          productDeviceType: null,
        },
        configurationItems: [],
        services: [],
        serviceIds: [],
        configurationItemIds: [],
        internalModels: [],
      };
      this.iconImage = [];
      this.previewImage = '';
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    async listProductById(id) {
      const headers = {
        'FULL-PRODUCT': true,
      };
      const response = await this.$apiManager.product.getProductById(id, headers);
      if (!response.data.regionIdentifiers) {
        response.data.regionIdentifiers = [];
      }
      if (!response.data.loadedDeviceTypeIds) {
        response.data.loadedDeviceTypeIds = [];
      }
      if (!response.data.propertyIds) {
        response.data.propertyIds = [];
      }
      Object.assign(this.form, response.data);
      if (this.form.children) {
        this.childrenString = this.form.children.join(',');
      }
      this.initImages();
      if (
        !this.$store.state.currentUser.permissions.find(
          (perrmission) => perrmission === 'rcs:product:update',
        )
      ) {
        this.permissionDisable = true;
      }
    },
    initImages() {
      if (this.form.iconDownloadUrl) {
        this.iconImage = [
          {
            url: this.form.iconDownloadUrl,
            filePath: this.form.icon,
            name: this.form.icon,
            uid: -1,
          },
        ];
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          if (this.form.childrenCount === 0) {
            delete this.form.children;
          }
          this.form.icon = this.iconImage[0] ? this.iconImage[0].filePath : '';
          this.form.internalModelIds = this.form.internalModels.map((model) => model.id);
          if (!this.permissionDisable) {
            this.$apiManager.product.updateProduct(this.form).then(() => {
              this.$message.success('修改成功');
              this.goBack();
            });
          } else {
            this.$apiManager.product.appUpdateProduct(this.form).then(() => {
              this.$message.success('修改成功');
              this.goBack();
            });
          }
        }
      });
    },
    goBack() {
      this.$router.back();
      this.dataInit();
    },
    handlePropertySelect() {
      const toDO = (select, properties) => {
        this.form.propertyIds = select;
        this.form.properties = properties;
      };
      this.$refs.productPropertiesSelector.selectedRowKeys = this.form.propertyIds;
      this.$refs.productPropertiesSelector.showCreateDialog(toDO);
    },
    handleRegionSelect() {
      const toDO = (select) => {
        this.form.regionIdentifiers = select;
      };
      this.$refs.regionSelectModal.selectedRowKeys = this.form.regionIdentifiers;
      this.$refs.regionSelectModal.showCreateDialog(toDO);
    },
    relationTips() {
      const h = this.$createElement;
      this.$info({
        title: '错误提示',
        content: h('div', {}, [
          h('h3', '该物模型/负载类型有关联的产品小类推荐列表'),
          h('p', '请先清空推荐列表后重试(暂未实现)'),
          // h('a-button', {
          //   on: {
          //     click: () => {
          //       window.console.log('click');
          //     },
          //   },
          //   style: {
          //     padding: '0px',
          //   },
          //   props: {
          //     type: 'link',
          //   },
          // }, '前往设置'),
        ]),
        onOk() {},
      });
    },
    handleLoadedDeviceSelect() {
      // this.relationTips();
      const toDO = (select, loadedDeviceTypes) => {
        this.form.loadedDeviceTypeIds = select;
        this.form.loadedDeviceTypes = loadedDeviceTypes;
      };
      this.$refs.loadedDeviceSelectModal.selectedRowKeys = this.form.loadedDeviceTypeIds;
      this.$refs.loadedDeviceSelectModal.showSelectDialog(toDO);
    },
    handleServiceSelect() {
      const toDO = (select, services) => {
        this.form.serviceIds = select;
        this.form.services = services;
      };
      this.$refs.productServiceSelector.selectedRowKeys = this.form.serviceIds;
      this.$refs.productServiceSelector.showCreateDialog(toDO);
    },
    handleConfigurationSelect() {
      const toDO = (select, configurationItems) => {
        this.form.configurationItemIds = select;
        this.form.configurationItems = configurationItems;
      };
      this.$refs.productConfigurationSelector.selectedRowKeys = this.form.configurationItemIds;
      this.$refs.productConfigurationSelector.showCreateDialog(toDO);
    },
    async customRequest(request, module, submodule, imageFile) {
      this.$refs.uploadProgress.visible = true;
      this.$apiManager.file
        .uploadFile(module, submodule, request.file)
        .then((fileUploadResponse) => {
          this.$refs.uploadProgress.visible = false;
          this.$message.success('上传成功');
          this.$store.commit('setUploadProgress', -1);
          if (fileUploadResponse.data.filePath) {
            const reader = new FileReader();
            reader.readAsDataURL(request.file);
            const params = {
              filePath: fileUploadResponse.data.filePath,
              uid: request.file.uid,
              status: 'done',
              name: request.file.name,
            };
            reader.onloadend = (e) => {
              const url = e.target.result;
              params.url = url;
              imageFile.push(params);
            };
          }
        })
        .catch(() => {
          this.$refs.uploadProgress.visible = false;
          this.$message.error('上传失败');
        });
    },
    iconCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.product,
        this.$constRepository.file.Submodule.iconImage,
        this.iconImage,
      );
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    removeiconImages(record) {
      this.iconImage = this.iconImage.filter((image) => image !== record);
    },
    handleDeleteProduct(target) {
      this.form.internalModels = this.form.internalModels.filter((model) => model.id !== target.id);
    },
    jumpToDetail(model) {
      this.$router.push({ name: 'productModelDetail', params: { id: model.id } });
    },
    handleAddBtn() {
      this.$refs.productModelTransferModal.openModal(this.form.internalModels);
    },
    handleSelectedModel(models) {
      this.form.internalModels = models;
    },
    handleProductTypeChange() {
      this.form.addressType = null;
      this.form.subAddressType = null;
    },
    handleChildrenChange() {
      if (this.childrenString) {
        this.form.children = this.childrenString.split(',');
      } else {
        this.form.children = [];
      }
    },
    // handleChildrenCountFocus() {
    //   this.relationTips();
    //   this.$refs.childrenCount.blur();
    // },
    // handleChildrenFocus() {
    //   this.relationTips();
    //   this.$refs.children.blur();
    // },
  },
  watch: {
    'form.childrenCount': {
      handler(newValue) {
        if (newValue === 0) {
          this.childrenString = '';
          this.form.children = [];
          this.form.childrenCountLimit = null;
        } else if (newValue > 0) {
          this.form.childrenCountLimit = null;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  .form {
    padding-top: 30px;
    width: 95%;
    /deep/ .ant-form-item-label {
      width: 150px;
    }
  }
}
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.group-btn {
  margin-left: 16.7%;
  .create-btn {
    margin-left: 2%;
  }
}
.w-1\/3 {
  width: 33.33333333%;
}
.w-1\/2 {
  width: 50%;
}
.w-full {
  width: 100%;
}
</style>
